.main-div {
    background: linear-gradient(to top right, rgb(241, 234, 255), rgb(255, 255, 255)), linear-gradient(to top left, #74dfff, rgb(255, 255, 255));

    .video-player {
        padding: 30px 15%;


        .video-title {
            font-size: 34px;
            font-weight: 400;
        }

        .video-description {
            font-size: 16px;
            font-weight: 400;
        }

        .button-container {
            cursor: pointer;
            display: flex;
            gap: 5px;

            button {
                font-size: 14px;
                background: none;
                border: none;
                cursor: pointer;
                font-weight: bold;
            }

            img {
                background: #534AD8;
                padding: 5px;
                border-radius: 50%;
            }
        }

        .video-container {
            background: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            border-radius: 20px;
        }

        .video {
            width: 100%;
            border-radius: 20px;
        }
    }
}

@media screen and (max-width:480px) {
    .main-div {

        .video-player {
            padding: 30px 5%;
            min-height: 78vh;

            .video-title {
                font-size: 24px;
                font-weight: 400;
                margin: 8px 0;
            }

            .button-container {
                button {
                    font-size: 11px;
                }
            }

            // .video-container {
            //     height: 410px;
            // }

            .video {
                height: 100%;
            }
        }
    }
}

@media screen and (min-width:481px) and (max-width:767px) {
    .main-div {
        .video-player {
            padding: 30px 5%;
            min-height: 78vh;

            .video-title {
                font-size: 24px;
                font-weight: 400;
                margin: 8px 0;
            }

            .button-container {
                button {
                    font-size: 11px;
                }
            }

            .video-container {
                height: 410px;
            }

            .video {
                height: 100%;
                border-radius: 20px;
            }
        }
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .main-div {
        .video-player {
            padding: 30px 5%;
            min-height: 78vh;

            .video-title {
                font-size: 24px;
                font-weight: 400;
                margin: 8px 0;
            }

            .button-container {
                button {
                    font-size: 11px;
                }
            }

            .video-container {
                height: 410px;
            }

            .video {
                height: 100%;
                border-radius: 20px;
            }
        }
    }
}