.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 128px;

  &__logo {
    flex: 1;

    a {
      text-decoration: none;
    }

    img {
      border: none;
    }
  }

  &__mobiletitle {
    display: none;
  }

  &__links {
    position: fixed;
    height: 100%;
    background-color: white; // White background
    transform: translateX(100%); // Start off-screen to the right
    transition: transform 0.3s ease-out; // Smooth transition
    border-radius: 20px, 20px, 0px, 0px;

    a {
      margin: 0 10px;
      text-decoration: none;
      color: #1e2225;
      font-size: 18px;
    }
  }

  &__buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
      padding: 8px 16px;
      border-radius: 1000px;
      background: var(--background-primary-ffffff, #fff);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
      border: none;
      //   font-size: 16px;
      //   color: #475258;
      color: var(--background-variant-6475258, #475258);
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.14px;
      cursor: pointer;
    }
  }

  &__links__desktop {
    display: flex;
    justify-content: center;
    flex: 2;
    gap: 40px;

    a {
      text-decoration: none;
      color: #1e2225;
      // font-size: 18px;
      color: var(--background-variant-81-e-2225, #1e2225);
      font-family: Lexend;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__hamburger {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .navbar {
    background: #fff;
    padding: 0;

    &__logo {
      display: block;
      left: 0;
      top: 0;
    }

    &__mobiletitle {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100vw;

      span {
        font-size: 20px;
        font-weight: 400;
      }
    }

    &__links {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 10px;
      left: 10px;
      width: 95%;
      margin-top: 0px;
      border-radius: 20px;

      &.navbar__open {
        display: flex;
        transform: translateX(0);
        justify-content: flex-start;
        z-index: 1;

        .close-button {
          position: absolute;
          top: 10px;
          right: 17px;
          cursor: pointer;
        }

        .menu {
          display: flex;
          width: 90%;
          flex-direction: column;
          align-self: flex-start;
          margin-left: 20px;
          margin-top: 20%;
          height: 85%;
          // z-index: 9999;

          .menu-item {
            display: flex;
            width: 100%;
            padding: 18px 0px 18px 0px;
            gap: 24px;
            border: 0px 0px 1px 0px;
            // opacity: 0px;
            border-bottom: 1px solid #dae1f9;

            .text {
              flex: 1;
              font-size: 18px;
              font-weight: 400;
              line-height: 22.5px;
              text-align: left;
              color: #475258;
              z-index: 1;
            }
          }

          .title {
            color: #adbdcc;
            font-family: Lexend;
            font-size: 34px;
            font-weight: 400;
            line-height: 42.5px;
            letter-spacing: 0.0025em;
            text-align: left;
            margin-bottom: 20px;
          }

          .menu-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            // height: 95vh;
            flex: 1;
            gap: 10px;

            .menu-footer-text {
              font-size: 11px;
              font-weight: 400;
              color: #adbdcc;
            }
          }
        }
      }

      .navbar__closed {
        display: none;
      }
    }

    &__open {
      &__links {
        display: flex;
      }
    }

    &__buttons {
      display: none;
    }

    &__hamburger {
      display: flex;
      margin-right: 20px;
    }

    &__links__desktop {
      display: none;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .navbar {
    background: #fff;
    padding: 0;

    &__logo {
      display: block;
      left: 0;
      top: 0;
    }

    &__mobiletitle {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100vw;

      span {
        font-size: 20px;
        font-weight: 400;
      }
    }

    &__links {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 10px;
      left: 10px;
      width: 95%;
      margin-top: 0px;
      border-radius: 20px;

      &.navbar__open {
        display: flex;
        transform: translateX(0);
        justify-content: flex-start;

        .close-button {
          position: absolute;
          top: 10px;
          right: 17px;
          cursor: pointer;
        }

        .menu {
          display: flex;
          width: 90%;
          flex-direction: column;
          align-self: flex-start;
          margin-left: 20px;
          margin-top: 20%;

          .menu-item {
            display: flex;
            width: 100%;
            padding: 18px 0px 18px 0px;
            gap: 24px;
            border: 0px 0px 1px 0px;
            opacity: 0px;
            border-bottom: 1px solid #dae1f9;

            .text {
              flex: 1;
              font-size: 18px;
              font-weight: 400;
              line-height: 22.5px;
              text-align: left;
              color: #475258;
            }
          }

          .title {
            color: #adbdcc;
            font-family: Lexend;
            font-size: 34px;
            font-weight: 400;
            line-height: 42.5px;
            letter-spacing: 0.0025em;
            text-align: left;
            margin-bottom: 20px;
          }

          .menu-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 95vh;
            gap: 10px;

            .menu-footer-text {
              font-size: 11px;
              font-weight: 400;
              color: #adbdcc;
            }
          }
        }
      }

      .navbar__closed {
        display: none;
      }
    }

    &__open {
      &__links {
        display: flex;
      }
    }

    &__buttons {
      display: none;
    }

    &__hamburger {
      display: flex;
      margin-right: 20px;
    }

    &__links__desktop {
      display: none;
    }
  }
}
