.card {
    width: 95%;
    max-width: 400px;
    margin: 0 2rem;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 2rem;

    .cover {
        width: auto;
        margin: 20px;
        height: 180px;
        border-radius: 5px;
        background: #ececec;

        & {
            background-image: linear-gradient(120deg, #ececec 50%, #fafafa 60%, #fafafa 61%, #ececec 70%);
            background-size: 200%;
            background-position: 100% 0;
            animation: waves 2s linear infinite;
        }

        @keyframes waves {
            100% {
                background-position: -100% 0;
            }


        }
    }

    .info {
        padding: 20px;

        .line {
            height: 40px;
            background: #ececec;
            border-radius: 5px;
            margin-bottom: 20px;

            & {
                background-image: linear-gradient(120deg, #ececec 50%, #fafafa 60%, #fafafa 61%, #ececec 70%);
                background-size: 200%;
                background-position: 100% 0;
                animation: waves 2s linear infinite;
            }

            @keyframes waves {
                100% {
                    background-position: -100% 0;
                }


            }

        }

        .desc {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left-rect {
                width: 60px;
                height: 20px;
                border-radius: 5px;
                background: #ececec;

                & {
                    background-image: linear-gradient(120deg, #ececec 50%, #fafafa 60%, #fafafa 61%, #ececec 70%);
                    background-size: 200%;
                    background-position: 100% 0;
                    animation: waves 2s linear infinite;
                }

                @keyframes waves {
                    100% {
                        background-position: -100% 0;
                    }


                }
            }

            .right-rect {
                width: 50px;
                height: 20px;
                border-radius: 5px;
                background: #ececec;

                & {
                    background-image: linear-gradient(120deg, #ececec 50%, #fafafa 60%, #fafafa 61%, #ececec 70%);
                    background-size: 200%;
                    background-position: 100% 0;
                    animation: waves 2s linear infinite;
                }

                @keyframes waves {
                    100% {
                        background-position: -100% 0;
                    }


                }
            }
        }


    }
}

.cover,
.left-rect,
.right-rect,
.line {
    background-image: linear-gradient(120deg, #ececec 50%, #fafafa 60%, #fafafa 61%, #ececec 70%);
    background-size: 200%;
    background-position: 100% 0;
    animation: waves 2s linear infinite;
}

@keyframes waves {
    100% {
        background-position: -100% 0;
    }


}