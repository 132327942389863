.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #f1f7ff;
  color: #475258;
  padding: 60px 128px;
}

.footer-main-links {
  display: flex;
  flex: 1;
  justify-content: center;
  gap: 32px;
}



.footer-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &.logo-column {
    flex: 1;
    margin-right: 64px;

    .logo {
      margin-bottom: 32px;
    }

    .description {
      width: 100%;
      color: var(--background-variant-6475258, #475258);
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }

    .social-media {
      margin-top: 30px;
      display: flex;
      gap: 18px;

      img {
        background: #5d50c6;
        padding: 8px;
        border-radius: 50%;
      }
    }

    .rights {
      margin-top: 1.5rem;
      color: var(--background-variant-6475258, #475258);
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }
}

.footer-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .footer-link {
    // margin-bottom: 2rem;
    margin-bottom: 20px;

    a {
      text-decoration: none;
      // color: #475258;
      color: var(--background-variant-58595-a-6, #8595a6);
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.14px;
    }
  }

  .privacy {
    margin-top: 0px;
  }

  .footer-link-title {
    margin-bottom: 30px;



    a {
      text-decoration: none;
      //   font-weight: bold;
      //   color: #1e2225;

      color: var(--background-variant-81-e-2225, #1E2225);
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: -0.14px;
    }
  }
}

.mobile-footer {
  display: none;
}

.stop-scrolling {
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .footer {
    display: none;
  }

  .mobile-footer {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    // margin-top: 6.5rem;
    padding: 10px 20px;
  }

  .rights,
  .Privacy,
  .social-media {
    display: flex;
  }

  .rights {
    margin-right: 10px;
  }

  .Privacy {
    margin-right: 10px;
    margin-left: 10px;

    a {
      color: #000;
      text-decoration: none;
    }
  }

  .social-media {
    margin-left: 10px;
  }

  .social-media a {
    margin-right: 9px;
    margin-left: 2px;
  }
}