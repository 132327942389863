.main-div {
  .synposis-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
    overflow-y: scroll;
    height: 768px;
    background: url('../../assets/images/insights-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    padding: 0px 89px;
    @media (min-width: 769px) and (max-width: 1700px) {
      padding: 0px 43px;
    }

    .header-card {
      display: flex;
      // width: 518px;
      width: fit-content;
      padding: 15px 30px;
      justify-content: center;
      align-items: center;
      gap: 20px;
      border-radius: 0px 0px 24px 24px;
      border: 1px solid var(--transparent-tc-6-a-70-linear, rgba(158, 158, 158, 0.2));
      background: var(--transparent-tc-260-ffffff, rgba(255, 255, 255, 0.6));
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);

      .welcome {
        color: var(--background-variant-9000000, #000);
        font-family: Lexend;
        // font-size: 32px;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.08px;
        text-transform: uppercase;
        .icon {
          width: 40px;
          height: 40px;
        }
        .text {
          // font-size: 32px;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          margin-left: 8px;
        }
      }
    }

    .listening {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex: 1 0 0;

      .preview_title {
        display: flex;
        flex-direction: column;
        color: var(--background-variant-9000000, #000);
        font-family: Lexend;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.113px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .main-div {
    .section {
      min-height: 80vh;

      .gallery-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .main-div {
    .section {
      min-height: 80vh;
      padding: 0.5rem 15%;

      .gallery-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-div {
    .section {
      padding: 0.5rem 5%;
      min-height: 80vh;

      .gallery-title {
        display: none;
      }
    }
  }
}
