// Font Variables
$font-path: "../public/assets/fonts/Lexend/static/";
$font-family-lexend: 'Lexend';

@font-face {
  font-family: 'Lexend';
  font-style: 'regular';
  font-weight: 400;
  src: url('../public/assets/fonts/Lexend/static/Lexend-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: $font-family-lexend !important;
}

code {
  font-family: 'Lexend';
}