// body {
//   background: url("../../assets/images/canvas-background.svg");
//   background-size: cover;
//   background-repeat: no-repeat;
//   font-family: "Lexend";
// }

.main-div {
  background: none;
  .file-info {
    display: flex;
    padding: 4px;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    p {
      color: var(--background-variant-9000000, #000);
      font-family: Lexend;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
    }
    .buttons {
      display: flex;
      gap: 10px;

      .report-button {
        display: flex;
        height: 40px;
        padding: 8px 16px;
        align-items: center;
        gap: 5px;
        border-radius: 1000px;
        border-radius: 1000px;
        background: var(
          --gradients-green-01-bc-4709-c-25000-eb-5-f,
          linear-gradient(45deg, #01bc47 -4.75%, #09c250 67.65%, #00eb5f 110.13%)
        );
        // background: var(--background-variant-3-bfccd-9, #BFCCD9);
        color: var(--background-primary-ffffff, #fff);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.14px;
        border: none;
        cursor: pointer;
        img {
          display: flex;
          width: 16px;
          height: 16px;
          justify-content: center;
          align-items: center;
        }
      }
      .replay-button {
        display: flex;
        height: 40px;
        padding: 8px 16px;
        align-items: center;
        gap: 5px;

        border-radius: 1000px;
        background: var(--gradients-purple-534-ad-8834-c-9-d, linear-gradient(45deg, #534ad8 -23.5%, #834c9d 107.38%));
        // background: var(--background-variant-3-bfccd-9, #BFCCD9);
        color: var(--background-primary-ffffff, #fff);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.14px;
        border: none;
        cursor: pointer;

        img {
          display: flex;
          width: 16px;
          height: 16px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .listening {
    // display: flex;

    // margin-top:15%;

    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // gap: 20px;
    // flex: 1 0 0;
    // align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;

    img {
      // width: 132.5px;
      // height: 132.5px;
      flex-shrink: 0;
    }

    .listening-preview {
      margin-top: -60px;
      // top: 0;
      display: flex;
      // position: absolute;
      height: 50px;
      padding: 12px 20px;
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      border-radius: 4px;

      img {
        width: 31px;
        height: 26px;
      }

      .preview-text {
        color: var(--background-variant-6475258, #475258);
        text-align: center;
        font-family: Lexend;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .main-div {
    .section {
      min-height: 80vh;

      .gallery-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .main-div {
    .section {
      min-height: 80vh;
      padding: 0.5rem 15%;

      .gallery-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-div {
    .section {
      padding: 0.5rem 5%;
      min-height: 80vh;

      .gallery-title {
        display: none;
      }
    }
  }
}

.listening-conatnaier1 {
  margin-top: 38px;
  margin-bottom: 65px;
  height: 768px;
  // padding: 20px;
  gap: 20px;
  border-radius: 20px;
  background: var(--background-primary-ffffff, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
}

@keyframes scale {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.loading-animation {
  animation: scale 1s ease-in-out infinite; // Apply animation to the .loading-animation class
}
