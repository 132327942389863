body {
    background: url("../assets/images/canvas-background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Lexend';
}

.main-div {
    background: linear-gradient(to top right, rgb(241, 234, 255), rgb(255, 255, 255)), linear-gradient(to top left, #74dfff, rgb(255, 255, 255));

    .section {

        // margin-top: 70px;
        padding: 0.5rem 10%;
        min-height: 70vh;
        background: linear-gradient(to top right, rgb(241, 234, 255), rgb(255, 255, 255)), linear-gradient(to top left, #74dfff, rgb(255, 255, 255));

        .gallery-title {
            margin-bottom: 1rem;

            h2 {
                line-height: 1;
                font-size: 34px;
                font-weight: 300;
                margin-bottom: 0;
            }

            p {
                font-size: 16px;
                margin-bottom: 2rem;
            }
        }

        .gallery-filters {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 1.5rem;

            .filter-option {
                border: none;
                border-radius: 20px;
                background: white;
                cursor: pointer;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 8px -4px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                padding: 10px 20px;
                font-size: 14px;

                &.selected {
                    background: #534AD8;
                    color: white;
                }
            }
        }

        .video-gallery {
            margin: 2rem 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .video-card {
                // width: 80%;
                width: 27%;
                margin: 1.5rem auto;
                border-radius: 20px;
                background: white;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                padding: 16px 20px;
                cursor: pointer;

                .thumbnail-img {
                    border-radius: 20px;
                }

                .video-thumbnail {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }

                .video-details {
                    display: flex;
                    flex-direction: column;

                    .description {
                        margin-bottom: 0;
                        color: #475258;
                        line-height: 1.5;
                        margin: 12px 0;
                        width: 100%;
                        height: 4.5em;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: wrap;
                    }

                    .length-filter {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 0;

                        .length {
                            margin-bottom: 0;
                            display: flex;
                            gap: 5px;
                            align-items: center;
                            justify-content: center;
                            font-size: 14px;
                            color: #475258;
                        }

                        .filter {
                            font-size: 14px;
                            padding: 2px 10px;
                            border-radius: 5px;
                        }

                        .filter-ELSA {
                            background-color: #E3EEFF;
                            color: #0058D2;
                        }

                        .filter-Smart {
                            background-color: #ECFCF2;
                            color: #01BC47;
                        }

                        .filter-Navigators {
                            background-color: #FFF5EA;
                            color: #FF3030;
                        }

                        .filter-Demo {
                            background-color: lightyellow;
                            color: #8B8000;
                        }
                    }
                }
            }
        }

    }
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    --b: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #514b82;
    -webkit-mask:
        repeating-conic-gradient(#0000 0deg, #000 1deg 70deg, #0000 71deg 90deg),
        radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: l5 1s infinite;
}

@keyframes l5 {
    to {
        transform: rotate(.5turn)
    }
}

/////media queries

@media screen and (max-width:480px) {
    .main-div {
        .section {
            min-height: 80vh;

            .gallery-title {
                display: none;
            }

            .gallery-filters {
                flex-wrap: wrap;
                gap: 18px;
            }

            .video-gallery {

                .video-card {
                    width: 95%;

                    .video-details {
                        .description {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .loader {
        left: 45%;
    }
}

@media screen and (min-width:481px) and (max-width:767px) {
    .main-div {
        .section {
            min-height: 80vh;
            padding: 0.5rem 15%;

            .gallery-title {
                display: none;
            }

            .gallery-filters {
                flex-wrap: wrap;
                gap: 18px;
            }

            .video-gallery {

                .video-card {
                    width: 95%;

                    .video-details {
                        .description {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .loader {
        left: 45%;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .main-div {
        .section {
            padding: 0.5rem 5%;
            min-height: 80vh;

            .gallery-title {
                display: none;
            }

            .gallery-filters {
                flex-wrap: wrap;
                gap: 18px;
            }

            .video-gallery {

                .video-card {
                    width: 40%;

                    .video-details {
                        .description {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .loader {
        left: 45%;
    }
}