.synopsis-footer {
  display: flex;
  // height: 125px;
  height: 31.75px;
  justify-content: center;
  align-items: center;
  // gap: 16px;
  gap: 12px;

  bottom: 0px;
  padding-bottom: 13px;
  .powered {
    color: var(--background-variant-6475258, #475258);
    text-align: center;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.05px;
  }
  img {
   
    display: flex;
    width: 150px;
    height: 28px;
    padding: 1.533px 7.56px 1.118px 1.628px;
    align-items: center;
  }
  .synopsis-event {
    color: var(--background-variant-9000000, #000);
    text-align: center;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.05px;
  }
}
