.main-div {
  .template-container-row {
    display: flex;
    padding-top: 22px;
    padding-bottom: 20px;
    align-items: flex-start;
    gap: 48px;
    background: white;

    .post-insights-screen {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background: white;

      .post-insights-screen-title {
        color: var(--background-variant-9000000, #000);
        background: white;
        /* Footnote/Footnote - Medium - 13|18 */
        font-family: Lexend;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 138.462% */
      }
    }
  }
}
