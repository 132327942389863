body {
  background: url("../../assets/images/canvas-background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Lexend";
}

.main-div-container {
  background: linear-gradient(
      to top right,
      rgb(241, 234, 255),
      rgb(255, 255, 255)
    ),
    linear-gradient(to top left, #74dfff, rgb(255, 255, 255));

    .synposis-screen{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex: 1 0 0;
        overflow-y: scroll;
        height: 768px;
        background: url("../../assets/images/insights-background.svg");
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px;
        padding: 0px 89px;
        .listening {
 
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          
            flex: 1 0 0;
         
      
      
            img {
              width: 132.5px;
              height: 132.5px;
              flex-shrink: 0;
            }
      
            .preview_title {
              display: flex;
              flex-direction: column;
              color: var(--background-variant-9000000, #000);
              font-family: Lexend;
              font-size: 25px;
              font-size: 30px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.113px;
            }
          }
    }

  
  }


@media screen and (max-width: 480px) {
  .main-div {
    .section {
      min-height: 80vh;

      .gallery-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .main-div {
    .section {
      min-height: 80vh;
      padding: 0.5rem 15%;

      .gallery-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-div {
    .section {
      padding: 0.5rem 5%;
      min-height: 80vh;

      .gallery-title {
        display: none;
      }
    }
  }
}
