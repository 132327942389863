body,
html {
    margin: 0;
    padding: 0;
}

.login-container {
    display: flex;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background-color: #6660A8;
}

.left-side {
    position: relative;
    width: 45%;
    background-image: url('../../public/assets/img/all/login/Frame.png');
    background-position-y: center;
    background-size: cover !important;
}

.left-side::before {
    content: '';
    position: absolute;
    top: 25%;
    left: 15%;
    width: 55%;
    height: 40%;
    background-image: url('../../public/assets/img/all/login/login_card2.svg');
    background-size: cover;
    border-radius: 0.7rem;
    z-index: 1;
}

.left-side::after {
    content: '';
    position: absolute;
    top: 40%;
    left: 25%;
    width: 55%;
    height: 40%;
    background-image: url('../../public/assets/img/all/login/login_card1.svg');
    background-size: cover;
    border-radius: 0.7rem;
    z-index: 2;
}

.right-side {
    width: 65%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.email-model {
    text-align: left;
    margin-top: 10rem;
    margin-bottom: 12rem;
    //margin-right: 24rem;
    font-family: 'Lexend';
    margin-left: 3rem;

}

.email-model h2 {
    font-size: 1.8rem;
    font-weight: 350;
    margin-top: 1.5rem;
    margin-bottom: 10px;
    width: 100%;
}

.email-model h3 {
    font-size: 0.9rem;
    font-weight: 400;
    color: rgb(81, 80, 80);
    margin-top: 1.6rem;
    margin-bottom: 1rem;
}

.email-model h4 {
    font-size: 14px;
    font-weight: 550;
    color: 1px solid #7478C2;
    margin-top: 3.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.1rem;
}

.email-model h5 {
    font-size: 1.8rem;
    font-weight: 350;
    margin-top: 1.5rem;
    width: 70%;
}

.email-model h6 {
    font-size: 0.8rem;
    font-weight: 350;
    width: 70%;
}

.email-model p {
    width: 70%;
}

.email-model input {
    width: 40%;
    padding: 0;
    border: none;
    border-bottom: 1px solid #7478C2;
    margin-bottom: 10px;
    font-size: 1rem;
    outline: none;
    font-family: 'Lexend';
}

.email-model input.valid {
    background-image: url('../../public/assets/img/all/status-icon.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 15px;
}

.input-wrapper {
    position: relative;
}

.input-wrapper input {
    padding-right: 20px;
}

// .input-wrapper .error-image {
//     position: absolute;
//     top: 35%;
//     margin-left: 21.5rem;
//     transform: translateY(-50%);
//     background-image: url('../../public/assets/img/all/error-content.svg');
//     background-size: contain;
//     width: 20px;
//     height: 20px;
// }


.email-model button {
    border: none;
    color: #FFFFFF;
    height: 1.8rem;
    width: auto;
    padding: 0.5rem;
    border-radius: 0.4rem;
    margin-top: 2rem;
    font-size: 0.8rem;
    background: #534AD8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lexend';
}


.email-model button:disabled {
    background-color: rgb(197, 192, 192);
}

.error-message {
    color: red;
    max-width: 65%;
}

.bg {
    background-image: url('../../public/assets/img/all/rozie-logo.svg');
    //margin-left: 4rem;
    width: 12rem;
    height: 2.3rem;
    margin-top: 9rem;
    margin-bottom: 1rem;
    background-size: cover;
    // margin-right: 40.2rem;
}

.sub {
    font-size: 0.5rem;
    color: #999;
    text-align: left;
    //margin-left: 3.6rem;
    margin-bottom: 1.5rem;
    margin-top: 10rem;
    // margin-right: 1.2rem;
    font-family: 'Lexend';
}

.login-footer {
    display: none;
}

.social-media {
    display: flex;
    gap: 10px;
}



@media (max-width: 768px) {

    .login-container {
        flex-direction: column;
        width: 100%;
    }

    .left-side {
        height: 42%;
        width: 100%;
    }

    .left-side::before {
        top: 53%;
        left: 43%;
        transform: translate(-50%, -50%);
        margin-left: 1rem;
        size: 1rem;
        height: 20rem;
        width: 19rem;

    }

    .left-side::after {
        content: none;
    }

    .right-side {
        height: 58%;
        width: 100%;
    }

    .email-model {
        margin-left: 0rem;
        margin-top: auto;
        margin-bottom: 0rem;
        width: 80%;

    }

    .email-model input {
        width: 75%;
    }

    .input-wrapper .error-image {
        margin-right: 4.8rem;
    }

    .email-model h2 {
        font-size: 1.5rem;
        width: 100%;
    }

    .email-model h3 {
        font-size: 0.8rem;
    }

    .email-model h4 {
        font-size: 12px;
    }

    .email-model h5 {

        font-size: 1.6rem;
        font-weight: 350;
        width: 75%;

    }

    .email-model h6 {
        margin-bottom: 10rem;
        font-size: 0.8rem;
        font-weight: 350;
    }

    .email-model p {
        font-size: 0.8rem;
        width: 90%;
    }



    .bg {
        margin-right: 15rem;
        //margin-top: 2rem;
        margin-bottom: auto;
    }

    .sub {
        display: none;
    }

    .login-footer {
        display: flex;
        font-size: small;
        justify-content: space-between;
        align-items: center;
        background-color: #FFFFFF;
        margin-top: 3rem;
        //padding: 10px 20px;
        margin-left: 0;
        overflow-x: hidden;
        width: 90%;
        //margin: 0 auto;
    }

    .rights,
    .Privacy,
    .social-media {
        display: flex;
    }

    .rights {
        margin-right: 10px;
    }

    .Privacy {
        margin-right: 10px;
        margin-left: 10px;
    }

    .social-media {
        margin-left: 10px;
    }

    .social-media a {
        margin-right: 9px;
        margin-left: 2px;
    }


}