.main-div {
  display: flex;
  flex-direction: column;
  min-height: 650px;

  .synopsis-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1;
    overflow-y: scroll;
    height: auto;
    background: url('../../../assets/images/insights-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    padding: 0px 89px;
    @media (min-width: 769px) and (max-width: 1700px) {
      padding: 0px 43px;
    }

    .header-card-session {
      display: flex;
      width: fit-content;
      padding: 15px 30px;
      justify-content: center;
      align-items: center;
      gap: 20px;
      border-radius: 0px 0px 24px 24px;
      border: 1px solid var(--transparent-tc-6-a-70-linear, rgba(158, 158, 158, 0.2));
      background: var(--transparent-tc-260-ffffff, rgba(255, 255, 255, 0.6));
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);

      .welcome {
        color: var(--background-variant-9000000, #000);
        font-family: Lexend;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.08px;
        text-transform: uppercase;
        .icon {
          width: 40px;
          height: 40px;
        }
        .text {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          margin-left: 8px;
        }
      }
    }
    .snapShot {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      .snapShotPoint {
        display: flex;
        flex-direction: row;
        color: var(--background-variant-9000000, #000);
        font-family: Lexend;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.113px;
        // margin-bottom: 20px;

        // margin-bottom: 45px;

        .title {
          margin-left: 14px;
          color: var(--background-variant-9000000, #000);
          font-family: Lexend;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.06px;
        }
      }

      .take-ways-content,
      .recommendations-content {
        margin-top: 45px;
        width: 100%;
        // margin-left: 81px;
      }

      .take-ways {
        display: flex;
        flex-direction: row;
        color: var(--background-variant-9000000, #000);
        font-family: Lexend;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.113px;

        &.summary {
          margin-top: 20px;
          margin-left: 35px;
        }

        &.key {
          margin-bottom: 45px;
          margin-left: 30px;
        }

        .icon {
          margin-left: 10px;
          margin-right: 14px;
        }

        .insight-title {
          font-size: 24px !important;
          font-weight: 400;
        }
      }

      .circle-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 89px;
        gap: 70px;
        flex-wrap: wrap;
        margin-top: 45px;

        @media (min-width: 769px) and (max-width: 1700px) {
          padding: 0 75px;
          gap: 70px;
        }
        .circle {
          width: 300px;
          height: 300px;
          flex-shrink: 0;
          border-radius: 1000px;
          border-bottom: 10px solid var(--gradients-green-01-bc-4709-c-25000-eb-5-f, #01bc47);
          background: var(--secondary-varient-6-f-1-f-7-ff, #f1f7ff);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          @media (min-width: 769px) and (max-width: 1700px) {
            width: 200px;
            height: 200px;
          }
        }

        .topic-title {
          text-align: center;
          font-size: 1.2em;
        }
      }
    }
  }

  .footer123 {
    margin-top: auto;
  }
}

@media screen and (max-width: 480px) {
  .main-div {
    .section {
      min-height: 80vh;

      .gallery-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .main-div {
    .section {
      min-height: 80vh;
      padding: 0.5rem 15%;

      .gallery-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-div {
    .section {
      padding: 0.5rem 5%;
      min-height: 80vh;

      .gallery-title {
        display: none;
      }
    }
  }
}
