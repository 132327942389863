body {
  // background: url("../assets/images/canvas-background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Lexend';
  background: linear-gradient(to top right, rgb(241, 234, 255), rgb(255, 255, 255)),
    linear-gradient(to top left, #74dfff, rgb(255, 255, 255));
}

.main-div {
  .section {
    padding: 0px 128px;
    min-height: 70vh;

    // background: linear-gradient(
    //     to top right,
    //     rgb(241, 234, 255),
    //     rgb(255, 255, 255)
    //   ),
    //   linear-gradient(to top left, #74dfff, rgb(255, 255, 255));
    .main-title {
      display: flex;
      // margin-top: 60px;
      justify-content: space-between;
      // margin-bottom: 55px;
      .gallery-title {
        h2 {
          margin-top: 60.63px;
          color: var(--background-variant-9000000, #000);
          font-family: Lexend;
          font-size: 34px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.085px;
          margin-bottom: 0;
        }

        p {
          color: var(--background-variant-9000000, #000);
          font-family: Lexend;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          //   margin-bottom: 2rem;
        }
      }
      .file-limit-alert {
        margin-top: 61.63px;
        display: flex;
        padding: 0px 20px 0px 20px;
        align-items: flex-end;
        height: 70px;
        right: 0px;
        flex-direction: column;
        justify-content: center;
        gap: 3px;
        border-radius: 20px;
        // border-right: 4px solid var(--gradients-green-01-bc-4709-c-25000-eb-5-f, #01BC47);
        background: var(
          --transparent-tc-6-a-70-linear,
          linear-gradient(239deg, rgba(158, 158, 158, 0.14) -5.77%, rgba(208, 208, 208, 0.21) 104.9%)
        );
        background-blend-mode: plus-darker;
        .remaining-file {
          align-items: flex-end;
          color: var(--background-variant-9000000, #000);

          /* Title/Sub Title - Regular - 18|Auto */
          font-family: Lexend;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .audio-lengh {
          color: var(--background-variant-9000000, #000);

          /* Body/Body (Auto Space) - Regular - 14|auto */
          font-family: Lexend;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.14px;
        }
      }
    }
    button {
      cursor: pointer;
    }

    .box {
      .apply-opacity {
        opacity: 0.5;
      }

      .video-upload-container {
        display: flex;
        // height: 175px;
        padding: 25px 385px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 20px;
        border: 1px dashed var(--primary-varient-2534-ad-8, #534ad8);
        background: var(--selection-selected-2-eae-9-ff, #eae9ff);

        &.upload {
          background: var(--selection-selected-2-eae-9-ff, #c9c6fa);
        }

        .file-name {
          color: var(--primary-varient-2534-ad-8, #534ad8);
          text-align: center;
          font-family: Lexend;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }

        .upload-text {
          color: var(--background-variant-81-e-2225, #1e2225);
          text-align: center;
          font-family: Lexend;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.14px;
        }

        .button-container {
          display: flex;
          // height: 32px;
          padding: 5px 12px;
          align-items: center;
          gap: 5px;
          flex-shrink: 0;
          border-radius: 4px;
          background: var(--primary-varient-2534-ad-8, #534ad8);
          color: var(--background-primary-ffffff, #fff);
          font-family: Lexend;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.14px;
          cursor: pointer;
        }

        .error-text {
          color: var(--alerts-warning-ff-4-a-4-a, #ff4a4a);
          text-align: center;
          font-family: Lexend;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          margin: 0px;
        }

        .select-input {
          display: flex;
          align-items: center;
          flex-direction: row;
          .input-dropdown-container {
            display: flex;
            align-items: center;
            position: relative;
            .domain-input {
              padding-right: 30px;
              border: none;
              display: flex;
              background: none;
              padding: 11px 0px;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 10px;
              align-self: stretch;
              width: 300px;
              outline: none;
              color: var(--background-variant-6475258, #475258);
              font-family: Lexend;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.14px;

              &::placeholder {
                color: var(--background-variant-4-adbdcc, #adbdcc);
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.14px;
              }
            }
            .dropdown-button {
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              // width: 30px;
              background: transparent;
              border: none;
              cursor: pointer;
            }

            .dropdown-menu {
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              border-radius: 4px;
              background: var(--background-primary-ffffff, #fff);
              box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
              list-style: none;
              margin: 0;
              padding: 0;
              z-index: 1000;
              margin-top: 9px;
              display: flex;
              padding: 16px 0px;
              flex-direction: column;
              align-items: flex-start;
              gap: 5px;
              align-self: stretch;
              .dropdown-title {
                padding: 8px 20px;
                color: var(--background-variant-4-adbdcc, #adbdcc);
                font-family: Lexend;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
              }
            }

            .dropdown-item {
              display: inline-flex;
              padding: 8px 20px;
              width: 300px;
              align-items: center;
              gap: 10px;
              color: var(--background-variant-6475258, #475258);
              flex: 1 0 0;
              font-family: Lexend;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.14px;
              cursor: pointer;
            }
          }

          .upload-cancel-button-container {
            display: flex;

            .upload {
              background: var(--secondary-varient-201-bc-47, #01bc47);
              margin-right: 10px;
              color: var(--background-primary-ffffff, #fff);
              font-family: Lexend;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.14px;
              border-radius: 100px;
              outline: none;
              height: 32px;
              padding: 5px 20px;
              align-items: center;
              gap: 5px;
              border: none;
              margin-left: 12px;
              cursor: pointer;
            }
            .cancel {
              background: var(--background-variant-58595-a-6, #8595a6);
              color: var(--background-primary-ffffff, #fff);
              font-family: Lexend;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.14px;
              border-radius: 100px;
              height: 32px;
              padding: 5px 20px;
              align-items: center;
              gap: 5px;
              border: none;
              cursor: pointer;
            }
          }
        }
      }
    }

    .file-wrapper {
      display: flex;
      gap: 13px;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 38px;
    }

    .file-card {
      display: flex;
      width: 23vw;
      padding: 18px 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 13px;
      flex-shrink: 0;
      border-radius: 12px;
      border: 0px solid var(--primary-varient-2534-ad-8, #534ad8);
      background: var(--background-primary-ffffff, #fff);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    }

    .file-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 14px;
      align-self: stretch;
      cursor: pointer;
    }
    .selected-file {
      border: 1px solid #534ad8;
    }

    .file-contanier img {
      width: 41.24px;
      height: 54.127px;
    }

    .file-name {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .file-title {
      display: flex;
      color: var(--background-variant-81-e-2225, #1e2225);
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.14px;
    }

    .play {
      display: flex;
      align-items: flex-start;
      gap: 6px;
    }

    .play img {
      width: 18px;
      height: 18px;
    }

    .play-title {
      color: var(--primary-varient-2534-ad-8, #534ad8);
      font-family: Lexend;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
    .duration {
      color: var(--background-variant-58595-a-6, #8595a6);
      font-family: Lexend;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      gap: 8px;
      display: flex;
      align-items: center;
      margin-left: 8px;
      justify-content: center;
      img {
        width: 4px;
        height: 4px;
      }
    }

    .progress-bar-container {
      width: 100%;
      display: block;
      overflow: hidden;
      height: 10px;
      align-items: center;
      border-radius: 100px;
      background: var(--selection-selected-6-ebeff-5, #ebeff5);
      margin-bottom: 3.5px;
    }
    .duration-time {
      margin-bottom: 3.5px;
    }

    .progress-bar {
      height: 100%;
      background: var(--secondary-varient-201-bc-47, #01bc47);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
      width: 0;
      transition: width 0.5s;
    }
    .processing-container {
      display: flex;
      flex-direction: row;
      font-family: Lexend;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;
      color: #534ad8;
      align-items: center;
      gap: 5px;

      &.blink {
        animation: blinkAnimation 2s infinite ease-in-out; /* Updated duration and timing function */
      }

      img {
        height: fit-content;
        width: fit-content;
      }
    }
  }
}

.audio-play {
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  margin-bottom: 18px;
  margin-top: 18px;
  cursor: pointer;
  .progress-bar-container {
    width: 100%;

    overflow: hidden;
    height: 10px;
    border-radius: 100px;
    background: var(--selection-selected-6-ebeff-5, #ebeff5);
  }

  .progress-bar {
    height: 100%;
    background: var(--secondary-varient-201-bc-47, #01bc47);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    width: 0;
    transition: width 0.5s;
  }
  .volume-container {
    img {
      height: 25px;
      width: 25px;
    }
  }
}

/////media queries

@media screen and (max-width: 480px) {
  .main-div {
    .section {
      min-height: 80vh;

      .gallery-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .main-div {
    .section {
      min-height: 80vh;
      padding: 0.5rem 15%;

      .gallery-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-div {
    .section {
      padding: 0.5rem 5%;
      min-height: 80vh;

      .gallery-title {
        display: none;
      }
    }
  }
}

.listening-container {
  margin-top: 38px;
  margin-bottom: 65px;
  height: auto;
  padding: 20px;
  gap: 20px;
  border-radius: 20px;
  background: var(--background-primary-ffffff, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
}

@keyframes blinkAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
